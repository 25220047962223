:root {
  /* Import theme colors as CSS variables */
  --color-primary: #2C3E50;
  --color-primary-light: #3E5771;
  --color-primary-dark: #1A252F;
  --color-primary-contrast: #FFFFFF;
  
  --color-secondary: #16A085;
  --color-secondary-light: #1ABC9C;
  --color-secondary-dark: #0E6655;
  --color-secondary-contrast: #FFFFFF;
  
  --color-background: #F5F7FA;
  --color-light-grey: #E5E9F0;
  --color-medium-grey: #9CA3AF;
  --color-dark-grey: #4B5563;
  --color-text: #1F2937;
  
  --color-success: #10B981;
  --color-warning: #F59E0B;
  --color-error: #EF4444;
  --color-info: #3B82F6;
  
  /* Typography */
  --font-family-primary: 'Inter', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --font-family-heading: 'Poppins', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-2xl: 2.5rem;
  --spacing-3xl: 3rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-family-primary);
  color: var(--color-text);
  background-color: var(--color-background);
  line-height: 1.6;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: var(--spacing-lg) var(--spacing-md);
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-heading);
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
  font-weight: 600;
  line-height: 1.2;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  margin-bottom: var(--spacing-md);
}

a {
  color: var(--color-secondary);
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover {
  color: var(--color-secondary-dark);
}

button, .button {
  background-color: var(--color-primary);
  color: var(--color-primary-contrast);
  border: none;
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover, .button:hover {
  background-color: var(--color-primary-light);
}

.button-secondary {
  background-color: var(--color-secondary);
  color: var(--color-secondary-contrast);
}

.button-secondary:hover {
  background-color: var(--color-secondary-light);
}

@media (max-width: 768px) {
  .main-content {
    padding: var(--spacing-md) var(--spacing-sm);
  }
  
  h1 {
    font-size: 1.875rem;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  
  h3 {
    font-size: 1.25rem;
  }
}

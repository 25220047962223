.home-page {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3xl);
}

/* Hero Section */
.hero-section {
  background: linear-gradient(135deg, var(--color-primary-dark), var(--color-primary));
  color: var(--color-primary-contrast);
  padding: var(--spacing-3xl) var(--spacing-md);
  border-radius: 8px;
  text-align: center;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-section h1 {
  color: var(--color-primary-contrast);
  font-size: 2.5rem;
  margin-bottom: var(--spacing-md);
}

.subtitle {
  font-size: 1.25rem;
  margin-bottom: var(--spacing-xl);
  opacity: 0.9;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: var(--spacing-md);
}

.button {
  display: inline-block;
  background-color: var(--color-secondary);
  color: white;
  padding: var(--spacing-sm) var(--spacing-xl);
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.button:hover {
  background-color: var(--color-secondary-light);
  transform: translateY(-2px);
}

.button-outline {
  background-color: transparent;
  border: 2px solid var(--color-primary-contrast);
  color: var(--color-primary-contrast);
}

.button-outline:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Features Section */
.features-section {
  padding: var(--spacing-xl) var(--spacing-md) var(--spacing-3xl);
  text-align: center;
  background-color: var(--color-background);
}

.features-section h2 {
  margin-bottom: var(--spacing-2xl);
  position: relative;
  display: inline-block;
  padding-bottom: var(--spacing-sm);
}

.features-section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: var(--color-secondary);
  border-radius: 3px;
}

.services-container {
  margin-top: var(--spacing-xl);
  width: 100%;
  max-width: 1200px !important; /* Override MUI Container default max-width */
}

.features-grid {
  width: 100%;
  margin: 0 !important; /* Override any default grid margins */
}

.features-grid > .MuiGrid-item {
  display: flex;
  width: 100%;
  padding: var(--spacing-md);
}

@media (min-width: 960px) {
  .services-container {
    padding: 0 var(--spacing-xl);
  }
  
  .features-grid > .MuiGrid-item:nth-child(odd) {
    padding-right: var(--spacing-md);
  }
  
  .features-grid > .MuiGrid-item:nth-child(even) {
    padding-left: var(--spacing-md);
  }
}

.feature-card {
  background-color: white;
  padding: var(--spacing-xl);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  font-size: 2rem;
  color: var(--color-secondary);
  margin-bottom: var(--spacing-md);
}

.feature-card h3 {
  margin-bottom: var(--spacing-sm);
  color: var(--color-primary);
}

/* CTA Section */
.cta-section {
  background-color: var(--color-primary);
  color: var(--color-primary-contrast);
  padding: var(--spacing-3xl) var(--spacing-xl);
  border-radius: 8px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.cta-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background: linear-gradient(135deg, transparent 0%, rgba(22, 160, 133, 0.2) 100%);
}

.cta-content {
  position: relative;
  z-index: 2;
  max-width: 700px;
  margin: 0 auto;
}

.cta-section h2 {
  margin-bottom: var(--spacing-md);
  color: var(--color-primary-contrast);
}

.cta-section p {
  margin-bottom: var(--spacing-lg);
  color: rgba(255, 255, 255, 0.9);
}

.cta-section .button {
  background-color: var(--color-secondary);
  color: white;
  font-weight: 600;
  padding: var(--spacing-sm) var(--spacing-2xl);
}

.cta-section .button:hover {
  background-color: var(--color-secondary-light);
}

/* Loading state */
.loading {
  text-align: center;
  padding: var(--spacing-xl);
  color: var(--color-medium-grey);
  font-style: italic;
}

/* Error state */
.error-message {
  text-align: center;
  padding: var(--spacing-xl);
  color: var(--color-error);
  background-color: rgba(239, 68, 68, 0.1);
  border-radius: 8px;
  border: 1px solid var(--color-error);
  margin-bottom: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-md);
}

.retry-button {
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.retry-button:hover {
  background-color: var(--color-primary-light);
}

/* Adjust ServicesCard to match feature-card styles */
.features-grid .MuiCard-root {
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.features-grid .MuiCard-root:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }
  
  .subtitle {
    font-size: 1.1rem;
  }
  
  .hero-buttons {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .features-grid {
    grid-template-columns: 1fr;
  }
}
